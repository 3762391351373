export const RouteTypes = ["Road Road", "Outer Ring Road"];

export const BoundariesData = [
  {
    label: "Delhi",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [
      {
        label: "E delhi",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "w Delhi",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "N Delhi",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "s Delhi",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "C Delhi",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
    ],
  },
  {
    label: "Gurgaon",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [],
  },
  {
    label: "faridabad",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [],
  },
  {
    label: "Gaziabad",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [],
  },
  {
    label: "Noida",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [],
  },
];

export const InputItems = [
  { label: "Road Facing", id: "roadFacing" },
  { label: "Touch Points", id: "touchPoints" },
];

export const TouchPointsInputItems = [
  { label: "Malls", id: "malls" },
  { label: "Office", id: "office" },
  { label: "Cyber City", id: "cyberCity" },
  { label: "One Horizon", id: "oneHorizon" },
  { label: "Promenade", id: "promenade" },
];

export const DummyTGButtonNames = ["male", "18-25", "target-group-1"];
export const DummyBoundaryButtonNames = [
  "Delhi- W Delhi",
  "Delhi- E Delhi",
  "Delhi- N Delhi",
  "Delhi- S Delhi",
  "Delhi- C Delhi",
  "Delhi- N Delhi",
  "Delhi- S Delhi",
  "Delhi- C Delhi",
];

// Constants
export const TargetGroupData = [
  {
    label: "Gender",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [
      {
        label: "Male",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "Female",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
    ],
  },
  {
    label: "Age Group",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [
      {
        label: "18-25",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "26-35",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "36-45",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "45-above",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
    ],
  },
  {
    label: "Income Group",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [
      {
        label: "SEC A",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "SEC B",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
      {
        label: "SEC C",
        level: 2,
        isSelected: false,
        status: "unchecked",
        children: [],
      },
    ],
  },
  {
    label: "Behavioral",
    level: 1,
    isSelected: false,
    status: "unchecked",
    children: [],
  },
];
