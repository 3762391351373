import { useState } from "react";

// Components
import PlanCityViewMediaTable from "./PlanCityViewMediaTable";
import { SearchFilterAndSortMediaSection } from "../../../../pages/map-view/media-sites/MediaSitesUtils";

/**
 * Org Media Table With Actions
 */
function OrgMediaTableWithActions({
  mediaList = [],
  campaignMediaSites = [],
  selectedMediaSites = [],
  setSelectedMediaSites = () => {},
  isOrgMedia = true,

  // switch inventory specific
  inventoryToShow,
  setInventoryToShow = () => {},
}) {
  const [filteredMediaList, setFilteredMediaList] = useState([]);

  // listingViewLabels
  const listingViewLabels = {
    search: {
      placeholder: "Search media sites from inventory",
      fields: ["title"],
    },
  };

  return (
    <>
      <div className="my-3">
        <SearchFilterAndSortMediaSection
          mediaList={mediaList}
          setExploreSitesList={setFilteredMediaList}
          listingViewLabels={listingViewLabels}
          isOrgMedia={isOrgMedia}
          // inventory switch specific
          inventoryToShow={inventoryToShow}
          setInventoryToShow={setInventoryToShow}
        />
      </div>

      {/* Media Table */}
      <PlanCityViewMediaTable
        mediaList={filteredMediaList}
        campaignMediaSites={campaignMediaSites}
        selectedMediaSites={selectedMediaSites}
        setSelectedMediaSites={setSelectedMediaSites}
      />
    </>
  );
}

export default OrgMediaTableWithActions;
