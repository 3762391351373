import "./SelectedItemsList.scss";

export default function SelectedItemsList({
  selectedItems,
  title,
  customClassName = "bg-primary text-white",
  onDeselect = () => {},
}) {
  return (
    <>
      <h5>{title}</h5>
      <div className="d-flex flex-wrap align-items-center">
        {selectedItems.map((item) => (
          <div className="position-relative pr-2 mr-2 py-2" key={item}>
            <div className={`py-1 px-3 rounded-lg ${customClassName}`}>
              {item}
            </div>
            <span
              className="rounded-circle deselect"
              onClick={() => onDeselect(item)}
            >
              <i className="fas fa-times"></i>
            </span>
          </div>
        ))}
      </div>
      <hr />
    </>
  );
}
