import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Action
import {
  getRegionsData,
  updateCampaignDates,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { openSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";
import { resetRoadStretches } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// Constants and Utils
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import DurationSelector from "../../../mavin/components/duration-selector/DurationSelector";
import { PlainDropdown } from "../../../components/dropdown/Dropdown";

// CSS
import "./CampaignPlanningPage.scss";

function getInitialDateAndDuration(startTimestamp, endTimestamp) {
  if (!startTimestamp && !endTimestamp) {
    return { startDateObj: "", duration: "" };
  }

  const duration = getDifferenceInDays(startTimestamp, endTimestamp);
  return { startDateObj: new Date(startTimestamp), duration };
}

function StartDateAndDurationSection() {
  const dispatch = useDispatch();
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);

  const { startTimestamp, endTimestamp } = campaignPlan;
  const { startDateObj, duration } = getInitialDateAndDuration(
    startTimestamp,
    endTimestamp
  );

  const [startDate, setStartDate] = useState(startDateObj);

  // DatePicker Style
  const styleObject = {
    autoApply: true,
    border: true,
    buttonClassName: "start-date-selector p-2",
  };

  // Functions
  function selectDuration(duration) {
    const date = new Date(startDate);
    const endDate = new Date(date.setDate(date.getDate() + duration - 1));
    dispatch(updateCampaignDates({ startDate, endDate }));
  }

  return (
    <div className="d-flex">
      <div className="mr-3">
        <p className="mb-0">{"Start Date"}</p>
        {/* Single-Date-Selector ==> isSingleDatePicker={true} */}
        <BootstrapDateRangePicker
          isSingleDatePicker={true}
          initialStartDate={startDate}
          onApplyDates={setStartDate}
          minDate={new Date()}
          placeHolder={"Select Date"}
          styleObject={styleObject}
        />
      </div>
      <div className="">
        <p className="mb-0">{"Duration"}</p>
        <DurationSelector
          onItemSelect={selectDuration}
          startDate={startDate}
          initialDays={duration}
          buttonClassName="px-3 py-2"
        />
      </div>
    </div>
  );
}
function SingleCitySelectionSelector() {
  const dispatch = useDispatch();

  const regionsData = useSelector(
    (state) => state.campaignPlanning.regionsData
  );
  const { name: selectedCityName, id: selectedCityId } = regionsData || {};

  // Functions
  function onCitySelect(cityId) {
    dispatch(resetRoadStretches());
    dispatch(getRegionsData(cityId));
  }

  // for now only needed cities
  const citySelectItems = [{ id: "city-delhi-ncr", label: "Delhi NCR" }];

  return (
    <div className="mr-5">
      <p className="mb-0">Select City</p>
      {/* uncomment when modal needed to select city */}
      {/* <button
        type="button"
        className="btn border rounded-lg shadow-none p-2 dropdown-toggle"
        data-toggle="modal"
        data-target={`#${FormDataTargets.singleCitySelectionForm}`}
        onClick={() => dispatch(openSingleCitySelectionForm())}
      >
        {selectedCityName ? selectedCityName : "Select City"}
      </button> */}

      <PlainDropdown
        items={citySelectItems}
        className="btn shadow-none rounded-lg border px-2"
        placeHolder="Select City"
        selectedId={selectedCityId}
        onItemSelect={onCitySelect}
        dropMenuClassName="dropdown-fixed-height"
      />
    </div>
  );
}

/**
 * Section
 */
function PlanningHeaderSection() {
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);
  const campaignTitle = campaign ? campaign.title : "New Campaign";

  return (
    <>
      <h3 className="m-3">{campaignTitle}</h3>
      <hr className="my-0"></hr>
      <div className="d-flex align-items-center justify-content-between px-3 py-2">
        <SingleCitySelectionSelector />
        <StartDateAndDurationSection />
      </div>
      <hr className="my-0"></hr>
    </>
  );
}

export default PlanningHeaderSection;
