import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Marker, Popup } from "react-leaflet";

// Actions
import {
  downloadMonitoringMediaLogPdfReport,
  getMonitoringMediaLog,
  openMonitoringMediaLogImageModal,
} from "../../../actions/campaign-monitoring/MonitoringLogActions";
import { getMonitoringMediaSettings } from "../../../actions/campaign-monitoring/MonitoringSettingsActions";
import { getMediaDetails } from "../../../actions/campaign-media/CampaignMediaActions";

// Constants and Utils
import {
  DATE_FORMATS,
  MapZoom,
  FormDataTargets,
  MediaColor,
  PageSize,
} from "../../../constants/GeneralConstants";
import {
  constructDateString,
  constructSingleDateString,
} from "../../../common-utils/date-utils/DateUtils";
import { getMediaIcon } from "../../../utils/MediaUtils";

// Component
import Spinner from "../../../components/spinner/Spinner";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import Pagination from "../../../components/pagination/Pagination";
import {
  Dimensions,
  PriceInfo,
} from "../../../mavin/components/media-info/MediaInfo";
import MonitoringMediaLogImageModal from "./MonitoringMediaLogImageModal";
import {
  constructMediaLogAnalytics,
  groupItemsByDay,
  updateMediaLogs,
} from "../../../utils/MonitoringUtils";

// CSS
import "../CampaignMonitoring.scss";

// Page Constants
const frequencyDaysObject = {
  1: "Daily",
  2: "Once In 2 Days",
  3: "Once In 3 Days",
  5: "Once In 5 Days",
  7: "Weekly Once",
  10: "Once In 10 Days",
};

// Page Components
function DownloadReport({ campaignId, mediaId, mediaTitle }) {
  const dispatch = useDispatch();

  // Selector
  const downloadingPdfReport = useSelector(
    (state) => state.monitoringLog.downloadingMonitorPdfReport
  );

  //Checks for downloading report
  if (downloadingPdfReport) {
    return <Spinner />;
  }

  return (
    <p
      className="text-primary cursor-pointer download-report"
      onClick={() =>
        dispatch(
          downloadMonitoringMediaLogPdfReport(campaignId, mediaId, mediaTitle)
        )
      }
      data-html2canvas-ignore="true"
    >
      {"Download Monitoring Report"}
    </p>
  );
}

function RecordContainer({ mediaLogsDayWise }) {
  const dispatch = useDispatch();
  if (Object.keys(mediaLogsDayWise).length === 0) {
    return <p>No logs available.</p>;
  }

  return (
    <div className=" d-flex flex-wrap">
      {Object.keys(mediaLogsDayWise).map((date) => {
        const imageUrl = mediaLogsDayWise[date].latestImageUrl["url"];

        const deviceTimestamp =
          mediaLogsDayWise[date].latestImageUrl["timestamp"];
        const dayLog = mediaLogsDayWise[date].items;
        const dateString = constructSingleDateString(
          deviceTimestamp,
          DATE_FORMATS.full_month_with_date_year
        );

        return (
          <div className="card" key={date} style={{ width: "288px" }}>
            <h5 className="card-title pl-2  my-1">{dateString}</h5>
            <img
              src={imageUrl}
              className="log-record-image"
              data-toggle="modal"
              data-target={`#${FormDataTargets.mediaLogImageForm}`}
              onClick={() =>
                dispatch(openMonitoringMediaLogImageModal({ dayLog }))
              }
            />
          </div>
        );
      })}
    </div>
  );
}

function MediaLogsRecordSection({ updatedMediaLogsDayWise = {}, pagination }) {
  const { id, mediaId } = useParams();
  // Selector state
  const mediaLogLoading = useSelector(
    (state) => state.monitoringLog.monitoringMediaLogLoading
  );

  const dispatch = useDispatch();
  const { totalCount, pageNo, pageSize } = pagination;

  function loadMonitoringMediaLog(pageNumber, pageSizeCount) {
    dispatch(getMonitoringMediaLog(id, mediaId, pageNumber, pageSizeCount));
  }

  if (mediaLogLoading) {
    return (
      <div className="d-flex justify-content-center bg-alt mt-5 border p-2">
        <Spinner />
        <span className="mt-1 px-2">
          {"Media log records are loading, Please wait..."}
        </span>
      </div>
    );
  }

  return (
    <>
      <h3 className="mt-5">{totalCount} Records Found</h3>
      <hr className="divider mb-2"></hr>

      <RecordContainer mediaLogsDayWise={updatedMediaLogsDayWise} />

      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={loadMonitoringMediaLog}
      />
    </>
  );
}
function LogMap({ media = {} }) {
  const zoom = MapZoom.zoomLevel10;
  const { latitude, longitude } = media;
  const position = [latitude, longitude];
  const campaignMediaIcon = getMediaIcon(MediaColor.campaign);

  return (
    <>
      {latitude && longitude && (
        <LLMap center={position} zoom={zoom} tile="transport">
          <Marker position={position} icon={campaignMediaIcon}>
            <Popup>
              <b>{media.title}</b>
            </Popup>
          </Marker>
        </LLMap>
      )}
    </>
  );
}

// media image section
function MediaImageSection({ media }) {
  const fileItems = media.fileItems || [];
  const imageUrl = fileItems[0]?.originalUrl;

  // if image is present
  if (imageUrl) {
    return <img className="h-100 w-100" src={imageUrl}></img>;
  }

  return (
    <div className="d-flex align-items-center justify-content-center flex-column h-100">
      <i className="far fa-images fa-8x opacity-25 text-primary mb-3"></i>
      <p className="mb-3">
        <strong>No site image available</strong>
      </p>
    </div>
  );
}

function LogMediaImageWithMap({ media, campaignId }) {
  return (
    <div className="d-flex col px-0 media-image-map-container">
      <div className="col-6 px-0">
        {/* Media Image section */}
        <MediaImageSection media={media} campaignId={campaignId} />
      </div>
      <div className="col-6 px-0">
        {/* Log Map */}
        <LogMap media={media} />
      </div>
    </div>
  );
}

function MediaSettingsSection({
  mediaSettings = {},
  campaignId,
  media = {},
  mediaAnalytics = {},
}) {
  const { id: mediaId, title } = media;

  // if no-media-settings present then return below text.
  if (Object.keys(mediaSettings).length === 0) {
    return (
      <div className="col p-4 text-center">
        <h4>Media Monitoring is not assigned to anyone.</h4>
      </div>
    );
  }

  const {
    frequencyDays: frequencyDaysInCount,
    startTimestamp,
    endTimestamp,
    updatedOn,
    monitorUser,
  } = mediaSettings;

  const {
    duration = 0,
    jobDoneCount = 0,
    defaultedPercentage = 0,
  } = mediaAnalytics;

  const frequencyDaysInString = frequencyDaysObject[frequencyDaysInCount];

  // Time Frame
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const lastUpdateTime = constructSingleDateString(
    updatedOn,
    DATE_FORMATS.full_month_with_date_year
  );

  const monitoringSettingsInfo = [
    {
      label: "Frequency",
      value: frequencyDaysInString,
    },
    {
      label: "TimeFrame",
      value: dateString,
    },
    {
      label: "Last Updated Time",
      value: lastUpdateTime,
    },
    {
      label: "Media Analytics",
      value: (
        <>
          <p className="mb-0">
            Monitoring Done : {jobDoneCount} of {duration} days
          </p>
          <p>Default : {defaultedPercentage?.toFixed(1)}%</p>
        </>
      ),
    },
  ];

  return (
    <div className="col p-4">
      <div className="d-flex justify-content-between">
        <h5 className="pt-2">{"Monitoring Settings"}</h5>
        {/* Download report */}
        <DownloadReport
          campaignId={campaignId}
          mediaId={mediaId}
          mediaTitle={title}
        />
      </div>
      <div className="my-4">
        <span>{"Contact"}</span>
        <h3 className="mb-0">{monitorUser?.name}</h3>
        <span>
          {monitorUser?.emailId} | +91 {monitorUser?.phoneNo}
        </span>
      </div>

      <div className="d-flex justify-content-between my-4">
        {monitoringSettingsInfo.map((info, i) => (
          <div key={i}>
            <span>{info.label}</span>
            <span className="h4 d-block">{info.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function MonitoringMediaDetails({
  media,
  mediaSettings,
  campaignId,
  mediaAnalytics,
}) {
  return (
    <>
      <h1>{media.title}</h1>
      <p className="h3">
        <Dimensions media={media} />
        <PriceInfo media={media} /> | {media.region}
      </p>
      <div className="d-flex mt-4 border-top border-bottom">
        {/* Media-Image and MapView */}
        <LogMediaImageWithMap media={media} />

        {/* monitor-user-information */}
        <MediaSettingsSection
          mediaSettings={mediaSettings}
          campaignId={campaignId}
          media={media}
          mediaAnalytics={mediaAnalytics}
        />
      </div>
    </>
  );
}

/**
 * Main Page
 */
function MonitoringMediaLogViewPage() {
  const { id, mediaId } = useParams();

  // Selector State
  // getting "media-log-details"
  const mediaLogDetails =
    useSelector((state) => state.monitoringLog.monitoringMediaLog) || {};
  const mediaLogs = mediaLogDetails?.items;
  const pagination = mediaLogDetails?.pagination || {};

  // getting "media-details" and "city-id"
  const mediaDetailsLoading = useSelector(
    (state) => state.campaignMedia.mediaDetailsLoading
  );
  const media =
    useSelector((state) => state.campaignMedia.media.mediaDetails) || {};

  // getting "monitor-media-settings"
  const mediaSettings =
    useSelector((state) => state.monitoringSettings.mediaSettings) || {};

  // monitoring log image modal
  const openMonitoringMediaLogImageModal = useSelector(
    (state) => state.monitoringLog.openModal
  );

  const mediaLogsDayWise = groupItemsByDay(mediaLogs);

  const { frequencyDays, startTimestamp, endTimestamp } = mediaSettings;
  const updatedMediaLogsDayWise = updateMediaLogs(
    startTimestamp,
    endTimestamp,
    frequencyDays,
    mediaLogsDayWise
  );

  const mediaAnalytics = constructMediaLogAnalytics(updatedMediaLogsDayWise);
  // monitoring log image url
  const { dayLog } = useSelector(
    (state) => state.monitoringLog.processDataObject
  );

  const pageNumber = 1,
    pageSizeCount = PageSize.MonitoringMediaLog;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMediaDetails(id, mediaId));
    dispatch(getMonitoringMediaSettings(id, mediaId));
    dispatch(getMonitoringMediaLog(id, mediaId, pageNumber, pageSizeCount));
  }, [dispatch, id, mediaId, pageSizeCount]);

  // component loading
  if (mediaDetailsLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper" id="cont-media-log">
      {/* Page- Content */}
      <div className="page-content">
        {/* Media and MonitoringUser Details */}
        <MonitoringMediaDetails
          media={media}
          mediaSettings={mediaSettings}
          campaignId={id}
          mediaAnalytics={mediaAnalytics}
        />

        {/* Latest Log Records */}
        <MediaLogsRecordSection
          updatedMediaLogsDayWise={updatedMediaLogsDayWise}
          pagination={pagination}
          mediaSettings={mediaSettings}
        />

        {/* MODALS */}
        {openMonitoringMediaLogImageModal && dayLog?.length > 0 && (
          <MonitoringMediaLogImageModal dayLog={dayLog} />
        )}
      </div>
    </div>
  );
}

export default MonitoringMediaLogViewPage;
