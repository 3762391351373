import { RouteAnalysis } from "../../constants/action-constants/mavin-tools/RouteAnalysisActionConstants";

function constructData(data = {}) {
  const formatTime = (hour) => {
    const hour24 = parseInt(hour);
    const formattedHour = hour24 % 12 || 12;
    const period = hour24 < 12 ? "AM" : "PM";
    return `${formattedHour} ${period}`;
  };

  const {
    hourlyOTS,
    hourlyTgOTS,
    hourlyReach,
    hourlyTgReach,
    maleTgOts,
    feMaleTgOts,
    age18to25TgOts,
    age26to35TgOts,
    age36to45TgOts,
    age45AboveTgOts,
  } = data;

  const impressionsData = Object.keys(hourlyOTS).map((hour) => ({
    otsTotal: hourlyOTS[hour],
    tgOtsTotal: hourlyTgOTS[hour],
    time: formatTime(hour),
  }));

  const reachData = Object.keys(hourlyReach).map((hour) => ({
    reach: hourlyReach[hour],
    tgReach: hourlyTgReach[hour],
    time: formatTime(hour),
  }));

  const genderRatioData = Object.keys(maleTgOts).map((hour) => ({
    name: formatTime(hour),
    male: maleTgOts[hour],
    female: feMaleTgOts[hour],
  }));

  const ageSplitData = Object.keys(age18to25TgOts).map((hour) => ({
    name: formatTime(hour),
    "18-25": age18to25TgOts[hour],
    "26-35": age26to35TgOts[hour],
    "36-45": age36to45TgOts[hour],
    "45 above": age45AboveTgOts[hour],
  }));

  return {
    impressionsData,
    reachData,
    genderRatioData,
    ageSplitData,
  };
}

const initialState = {
  // By Road Segment Id
  // ---------------------------------------------------
  routeInfo: {},
  constructedRouteInfo: {},
  routeInfoError: "",
  routeInfoLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // By Road Segment Id
    // --------------------------------------------------------------------
    case RouteAnalysis.GET_ROUTE_SUMMARY:
      return {
        ...state,
        routeInfoLoading: true,
      };

    case RouteAnalysis.GET_ROUTE_SUMMARY_SUCCESS: {
      const { routeInfo } = action.payload;
      const constructedRouteInfo = constructData(routeInfo);
      return {
        ...state,
        constructedRouteInfo,
        routeInfo,
        routeInfoLoading: false,
      };
    }
    case RouteAnalysis.GET_ROUTE_SUMMARY_FAILURE:
      return {
        ...state,
        routeInfoLoading: false,
        routeInfoError: action.payload,
      };

    case RouteAnalysis.CLEAR_ROUTE_SUMMARY:
      return {
        ...state,
        routeInfo: {},
        constructedRouteInfo: {},
        routeInfoError: "",
        routeInfoLoading: false,
      };

    default:
      return state;
  }
};
