import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import {
  downloadExcelReport,
  downloadCampaignPdfReport,
} from "../../../actions/campaign-report/CampaignReportActions";

// Constants and Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import Spinner from "../../../components/spinner/Spinner";
import { Dropdown } from "../../../components/dropdown/Dropdown";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Page Components
function DownloadReport({ campaignId, campaignTitle, isMantaraySpecific }) {
  //Dispatch
  const dispatch = useDispatch();

  //Selector
  const downloadingXlsReport = useSelector(
    (state) => state.campaignReport.downloadXlsReportLoader
  );

  const downloadingPdfReport = useSelector(
    (state) => state.campaignReport.downloadCampaignPdfReportLoader
  );

  if (downloadingPdfReport || downloadingXlsReport) {
    return <Spinner className="spinner-border mx-3" />;
  }

  return (
    <Dropdown style="mt-0" buttonName="Download Campaign Report">
      <div className="dropdown-menu dropdown-menu-right">
        <button
          className="dropdown-item"
          onClick={() =>
            dispatch(
              downloadExcelReport(campaignId, campaignTitle, isMantaraySpecific)
            )
          }
        >
          XLSX
        </button>
        <button
          className="dropdown-item"
          onClick={() =>
            dispatch(downloadCampaignPdfReport(campaignId, campaignTitle))
          }
        >
          PDF
        </button>
      </div>
    </Dropdown>
  );
}

function ReportSection({ campaign = {}, isMantaraySpecific = false }) {
  //Selector
  const reportGenerating = useSelector(
    (state) => state.campaignReport.generateReportLoading
  );

  const { id: campaignId, title = "" } = campaign;

  // graph report page url
  const campaignGraphReportPageUrl =
    RedirectTo.graphReportsCampaignPageUrl.replace(":campaignId", campaignId);

  // Generate Report
  if (reportGenerating) {
    return (
      <AlertMessage
        className="mb-0 py-2"
        spinnerClassName="spinner-border-sm"
        textMessage="Your report is being generated. We will update here once it is generated."
      />
    );
  }

  // Download Report and view graph report
  return (
    <div className="d-flex align-items-center">
      {/* hiding option for mantaray */}
      {!isMantaraySpecific && (
        <Link to={constructRedirectPath(campaignGraphReportPageUrl)}>
          View Graph Report
        </Link>
      )}

      <DownloadReport
        campaignId={campaignId}
        campaignTitle={title}
        isMantaraySpecific={isMantaraySpecific}
      />
    </div>
  );
}

export default ReportSection;
