import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

// Actions
import { closeCampaignBudgetExceedForm } from "../../actions/campaign/CampaignBudgetActions";

// Constants and Utils
import { FormDataTargets, UrlKeys } from "../../../constants/GeneralConstants";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

// Components
import CheckBox from "../../../components/check-box/CheckBox";

/**
 * Campaign Budget Exceed Form
 */
function CampaignBudgetExceedForm({
  exceedingBudget,
  handleSubmit = () => {},
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  // state
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, []);

  // disable Budget Warning
  function disableBudgetWarning() {
    history.push({
      pathname: pathname,
      search: `?${UrlKeys.disableBudgetExceedWarning}=${checked}`,
    });
  }

  function handleSubmitFrom() {
    // update query params for showing again modal or not
    // if checked=true, then only updating queryParam
    if (checked) {
      disableBudgetWarning();
    }

    // callback
    handleSubmit();

    // close
    dispatch(closeCampaignBudgetExceedForm());
  }

  function handleReject() {
    // update query params for showing again modal or not
    // if checked=true, then only updating queryParam
    if (checked) {
      disableBudgetWarning();
    }

    // close modal
    dispatch(closeCampaignBudgetExceedForm());
  }

  return (
    <div
      className="modal fade"
      id={FormDataTargets.campaignBudgetExceedModal}
      data-backdrop="static"
    >
      <div className="modal-dialog mt-2" role="document">
        <div className="modal-content">
          {/* modal body */}
          <div className="modal-body px-5 pt-4 mt-2">
            <p className="mb-1">
              You are exceeding the given budget By :{" "}
              <span className="text-danger">
                {toLocaleString(Math.floor(exceedingBudget))}
              </span>
            </p>
            <p>Do you still want to continue with selecting media ?</p>

            <CheckBox
              boxStyle="p-0 mb-0 d-flex align-items-center"
              inputBoxStyle="m-0"
              checked={checked}
              onChange={() => setChecked(!checked)}
              checkBoxKey="Do Not Show Again"
            />
          </div>

          {/* Form Footer */}
          <div className="modal-footer border-0 px-5">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary rounded-lg"
              data-dismiss="modal"
              onClick={handleReject}
            >
              Reject Site
            </button>

            <button
              type="button"
              className="btn btn-sm btn-primary rounded-lg ml-2"
              data-dismiss="modal"
              onClick={handleSubmitFrom}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignBudgetExceedForm;
