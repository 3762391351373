import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Constant & Utils
import { OrgType } from "./constants/OrgTypeConstants";
import { getItem } from "./utils/localstorage";
import { LocalStorageKeys } from "./constants/GeneralConstants";
import { useGetErrorStatusCode } from "./utils/ErrorUtils";

// CSS
import "./App.scss";
import "bootstrap/dist/js/bootstrap.js";
import "react-toastify/dist/ReactToastify.css";

// Page Components
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

// Public Pages
import LoginPage from "./pages/auth/LoginPage";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";

// Private Pages
import HomePage from "./pages/home/Home";

// Pages :: Org Settings
import OrgProfilePage from "./pages/org/settings/OrgProfilePage";
import OrgUserListPage from "./pages/org/settings/OrgUserListPage";
import OrgUserCreatePage from "./pages/org/settings/OrgUserCreatePage";
import OrgUserManagePage from "./pages/org/settings/OrgUserManagePage";
import OrgSubscriptionPage from "./pages/org/settings/OrgSubscriptionPage";

// Pages :: Org Definitions
import TargetGroupListPage from "./pages/org/target-group/TargetGroupListPage";
import TargetGroupCreatePage from "./pages/org/target-group/TargetGroupCreatePage";
import TargetGroupViewPage from "./pages/org/target-group/TargetGroupViewPage";

import RoadStretchListPage from "./pages/org/road-stretch/RoadStretchListPage";
import RoadStretchCreatePage from "./pages/org/road-stretch/RoadStretchCreatePage";
import RoadStretchViewPage from "./pages/org/road-stretch/RoadStretchViewPage";

import DataLayerListPage from "./pages/org/data-layer/DataLayerListPage";
import DataLayerCreatePage from "./pages/org/data-layer/DataLayerCreatePage";
import DataLayerViewPage from "./pages/org/data-layer/DataLayerViewPage";

// Pages :: Org Inventory
import OrgMediaPricePage from "./pages/org/inventory/OrgMediaPricePage";
import OrgMediaDetailPage from "./pages/org/OrgMediaDetailPage";

// Pages :: Campaign
import CampaignList from "./pages/campaign/list/CampaignList";
// CampaignMapView (analyser+manager)
import CampaignMapView from "./pages/campaign/campaign-map-view/CampaignMapView";

// Monitoring-only
import MonitoringHomePage from "./pages/campaign-monitoring/home/MonitoringHomePage"; // unused
import MonitoringCampaignCreatePage from "./pages/campaign-monitoring/campaign-create/MonitoringCampaignCreatePage";
import MonitoringCampaignViewPage from "./pages/campaign-monitoring/campaign-view/MonitoringCampaignViewPage";
import MonitoringCampaignCitySummaryPage from "./pages/campaign-monitoring/campaign-city-summary/MonitoringCampaignCitySummaryPage";
import MonitoringCampaignCityViewPage from "./pages/campaign-monitoring/campaign-city-view/MonitoringCampaignCityViewPage";
import MonitoringMediaLogViewPage from "./pages/campaign-monitoring/campaign-media-log-view/MonitoringMediaLogViewPage";

// Help page
import OtsHelpPage from "./pages/help/OtsHelpPage";
import LtsHelpPage from "./pages/help/LtsHelpPage";
import CovidChangeHelpPage from "./pages/help/CovidChangeHelpPage";
import VideosHelpPage from "./pages/help/VideosHelpPage";
import DefinitionHelpPage from "./pages/help/DefinitionHelpPage";

// Mavin Campaign Planning Pages
import CampaignPlanSetupPage from "./pages/campaign-planning/campaign-plan-setup/CampaignPlanSetupPage";
import CampaignPlanOverviewPage from "./pages/campaign-planning/campaign-plan-overview/CampaignPlanOverviewPage";
import CampaignPlanUploadPage from "./pages/campaign-planning/campaign-plan-site-upload/CampaignPlanUploadPage";
import CampaignPlanCityviewPage from "./pages/campaign-planning/campaign-plan-cityview/CampaignPlanCityviewPage";

// Campaign Report
import CampaignCreatePage from "./pages/campaign-report/campaign-create/CampaignCreatePage";
import CampaignViewPage from "./pages/campaign-report/campaign-view/CampaignViewPage";
import CampaignCityViewPage from "./pages/campaign-report/campaign-city-view/CampaignCityViewPage";
import CampaignMediaViewPage from "./pages/campaign-report/campaign-media-view/CampaignMediaViewPage";

// Tools Pages
import ToolsDashboard from "./pages/mavin-tools/ToolsDashboard";
import OtsToolPage from "./pages/mavin-tools/OtsToolPage";
import LtsToolPage from "./pages/mavin-tools/LtsToolPage";
import DemographicsToolPage from "./pages/mavin-tools/DemographicsToolPage";
import SiteAnalyserToolPage from "./pages/mavin-tools/single-site-analyser/SiteAnalyserToolPage";
import ReachAndFrequencyToolPage from "./pages/mavin-tools/ReachAndFrequencyToolPage";
import CompareLocationsToolPage from "./pages/mavin-tools/CompareLocationsToolPage";
import CityAnalyserToolPage from "./pages/mavin-tools/city-analyser/CityAnalyserToolPage";
import OtsRfTool from "./pages/mavin-tools/ots-rf-tool/OtsRfToolPage";
import RouteAnalysisToolPage from "./pages/mavin-tools/route-analysis-tool/RouteAnalysisToolPage";

// Seller Pages
import SellerDashboardPage from "./pages/seller/SellerDashboardPage";
import SellerInventoryHomePage from "./pages/seller/SellerInventoryHomePage";
import SellerInventoryCityViewPage from "./pages/seller/SellerInventoryCityViewPage";
import SellerInventoryMapViewPage from "./pages/seller/SellerInventoryMapViewPage";
import SellerEditMediaPage from "./pages/seller/SellerEditMediaPage";
import SellerMediaDetailsPage from "./pages/seller/SellerMediaDetailsPage";
import SellerWebsiteSetupPage from "./pages/seller/seller-website-setup/SellerWebsiteSetupPage";
import SellerWebsitePreviewPage from "./pages/seller/SellerWebsitePreviewPage";
import SellerInventoryUpload from "./pages/seller/SellerInventoryUpload";

// Graph Reports Pages
import CampaignGraphReportPage from "./pages/graph-reports/campaign-report/CampaignGraphReportPage";

// Error Page
import ErrorPage from "./pages/error-pages/ErrorPage";

// Prooh Pages
import ProohApp from "./prooh/ProohApp";

// Mantaray Pages
import MantarayApp from "./mantaray/MantarayApp";

export function constructRouterPath(
  path,
  useExact = false,
  productSpecific = false
) {
  const productId = process.env.REACT_APP_PRODUCT_ID;
  // This case will be handled  the specific component to be rendered
  // for  product Specific route (Ex: Prooh Dashboard, campaignPlanning)
  if (useExact && productSpecific) {
    return `/${productId}${path}`;
  }

  // This case will handle the specific component to be rendered
  // for default routes (Ex: Dashboard for Mavin, campaignPlanning)
  if (useExact) {
    return path;
  }

  // This will handle the same component to be rendered for both productSpecific
  // and defualts routes (Ex: Monotoring )
  if (productId) {
    return [path, `/${productId}${path}`];
  }
  return path;
}

function getHomePage() {
  const organizationType = getItem(LocalStorageKeys.ORG_TYPE);
  if (organizationType === OrgType.MEDIA_AGENCY) {
    return <HomePage />;
  }

  if (organizationType === OrgType.MEDIA_OWNER) {
    return <SellerDashboardPage />;
  }

  if (organizationType === OrgType.BRAND_OWNER) {
    // TODO:: Replace with actual brand home page
    return <HomePage />;
  }

  return null;
}

// to handle page error
function PageErrorHandler({ children }) {
  const errorStatusCode = useGetErrorStatusCode();
  if (!errorStatusCode) {
    return <>{children}</>;
  }

  return <ErrorPage errorStatusCode={errorStatusCode} />;
}

function App() {
  return (
    <Router>
      <PageErrorHandler>
        <Switch>
          {/* URL redirects */}
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>

          {/* Public Pages */}
          <PublicRoute path="/login" component={LoginPage} />
          <PublicRoute path="/forgot-password" component={ForgotPasswordPage} />
          <PublicRoute
            path="/passwd_reset/token/:token"
            component={PasswordResetPage}
          />

          {/* Home/Landing Page */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/home", true)}
            component={getHomePage}
            sidebar={false}
          />

          {/* Org Settings */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/profile")}
            component={OrgProfilePage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/user/list")}
            component={OrgUserListPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/user/create")}
            component={OrgUserCreatePage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/user/:id")}
            component={OrgUserManagePage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/subscription")}
            component={OrgSubscriptionPage}
            sidebar={true}
          />

          {/* Org Definitions */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/target-group/list")}
            component={TargetGroupListPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/target-group/create")}
            component={TargetGroupCreatePage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath(
              "/org/target-group/update/:targetGroupId"
            )}
            component={TargetGroupCreatePage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/target-group/:id")}
            component={TargetGroupViewPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/road-stretch/list")}
            component={RoadStretchListPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/road-stretch/create")}
            component={RoadStretchCreatePage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/road-stretch/:id")}
            component={RoadStretchViewPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/data-layer/list")}
            component={DataLayerListPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/data-layer/create")}
            component={DataLayerCreatePage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/data-layer/:id")}
            component={DataLayerViewPage}
            sidebar={true}
          />

          <ProtectedRoute
            exact
            path={constructRouterPath("/org/media-price")}
            component={OrgMediaPricePage}
            sidebar={true}
          />

          {/* org media page */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/org/:orgId/inventory/media/:mediaId")}
            component={OrgMediaDetailPage}
            sidebar={false}
          />

          {/* Campaign Pages */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/campaign/list")}
            component={CampaignList}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/site-upload")}
            component={CampaignCreatePage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/campaign/:id/summary")}
            component={CampaignViewPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/campaign/:id/summary/:cityId")}
            component={CampaignCityViewPage}
            sidebar={true}
            topNav={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/campaign/:campaignId/media/:mediaId")}
            component={CampaignMediaViewPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/campaign/:id/:cityId/map"
            component={CampaignMapView}
            sidebar={false}
            topNav={false}
          />

          {/* Mavin-Campaign-Planning pages */}
          <ProtectedRoute
            exact
            path="/campaign/plan/setup"
            component={CampaignPlanSetupPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/campaign/:campaignId/plan/overview"
            component={CampaignPlanOverviewPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path="/campaign/:campaignId/plan/city/:cityId"
            component={CampaignPlanCityviewPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path="/campaign/:id/plan/:cityId/map"
            component={CampaignMapView}
            topNav={false}
          />
          <ProtectedRoute
            exact
            path="/campaign/:campaignId/plan/upload"
            component={CampaignPlanUploadPage}
            sidebar={false}
          />

          {/* Monitoring only pages */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/monitor/settings/home")}
            component={MonitoringHomePage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/monitor/settings/excel-uploads")}
            component={MonitoringCampaignCreatePage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/monitor/settings/campaign/:id/summary")}
            component={MonitoringCampaignViewPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath(
              "/monitor/settings/campaign/:id/city/:cityId"
            )}
            component={MonitoringCampaignCityViewPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath(
              "/monitor/settings/campaign/:id/city/:cityId/analytics"
            )}
            component={MonitoringCampaignCitySummaryPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath(
              "/monitor/log/campaign/:id/media/:mediaId"
            )}
            component={MonitoringMediaLogViewPage}
            sidebar={false}
          />

          {/* Help page */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/help/tutorial-videos")}
            component={VideosHelpPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/help/ots")}
            component={OtsHelpPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/help/lts")}
            component={LtsHelpPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/help/covid-change")}
            component={CovidChangeHelpPage}
            sidebar={true}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/help/terminology-definition")}
            component={DefinitionHelpPage}
            sidebar={true}
          />

          {/* TOOLS - Pages */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools")}
            component={ToolsDashboard}
            sidebar={false}
          />
          {/* <ProtectedRoute
            exact
            path={constructRouterPath("/tools/ots")}
            component={OtsToolPage}
            sidebar={false}
          /> */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools/lts")}
            component={LtsToolPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools/demographics")}
            component={DemographicsToolPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools/site-analyser")}
            component={SiteAnalyserToolPage}
            sidebar={false}
          />
          {/* <ProtectedRoute
            exact
            path={constructRouterPath("/tools/reach-and-frequency")}
            component={ReachAndFrequencyToolPage}
            sidebar={false}
          /> */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools/compare-locations")}
            component={CompareLocationsToolPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools/city-analyser/:regionId")}
            component={CityAnalyserToolPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools/ots")}
            component={OtsRfTool}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath("/tools/route-analysis")}
            component={RouteAnalysisToolPage}
            sidebar={false}
          />

          {/* Seller Pages */}
          <ProtectedRoute
            exact
            path="/seller/dashboard"
            component={SellerDashboardPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/seller/inventory"
            component={SellerInventoryHomePage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/seller/inventory/city/:cityId"
            component={SellerInventoryCityViewPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/seller/inventory/city/:cityId/map"
            component={SellerInventoryMapViewPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/seller/:sellerId/inventory/media/:mediaId/edit"
            component={SellerEditMediaPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path={constructRouterPath(
              "/seller/:sellerId/inventory/media/:mediaId"
            )}
            component={SellerMediaDetailsPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/seller/website/setup"
            component={SellerWebsiteSetupPage}
            sidebar={false}
          />
          <ProtectedRoute
            exact
            path="/seller/website/preview"
            component={SellerWebsitePreviewPage}
            sidebar={false}
            topNav={false}
          />
          <ProtectedRoute
            exact
            path="/seller/inventory/upload"
            component={SellerInventoryUpload}
            sidebar={false}
          />

          {/* Graph Reports - Pages */}
          <ProtectedRoute
            exact
            path={constructRouterPath("/graph-reports/campaign/:campaignId")}
            component={CampaignGraphReportPage}
            sidebar={false}
          />

          {/* Mantaray/Prooh Related Pages */}
          <MantarayApp />
          <ProohApp />
        </Switch>
      </PageErrorHandler>
    </Router>
  );
}
export default App;
